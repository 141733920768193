<template>
	<div class="page_content">
		<div class="page_edit_content">
			<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 8 }" >
			    <a-form-item required  label="规则名称">
			      <a-input placeholder='请填写规则名称'  v-model:value="form.name" />
			    </a-form-item>
			   <a-form-item required  label="白班合格时长">
			     <a-input suffix='小时'  v-model:value="form.day_pass_time" />
			   </a-form-item>
			   <a-form-item required label="夜班合格时长">
			     <a-input suffix='小时'  v-model:value="form.night_pass_time" />
			   </a-form-item>
			   <a-form-item  label="合格天数">
			     <a-input suffix='天'  v-model:value="form.pass_num" />
			   </a-form-item>
			   
			   <a-form-item required  label="晚班应执行天数">
			     <a-input suffix='天'  v-model:value="form.night_pass_num" />
			   </a-form-item>
			   <a-form-item  label="免执行天数">
			     <a-input suffix='天'  v-model:value="form.unneed_day" />
			   </a-form-item>
			   <a-form-item required  label="白班执行时间">
			     <a-time-picker style='width:49%' v-model:value="form.day_start_time"
			     	valueFormat="HH:mm:ss" />~<a-time-picker style='width:49%' v-model:value="form.day_end_time"
			     	valueFormat="HH:mm:ss" />
			   </a-form-item>
			   <a-form-item  required label="晚班执行时间">
			    <a-time-picker style='width:49%' v-model:value="form.night_start_time"
			    	valueFormat="HH:mm:ss" />~<a-time-picker style='width:49%' v-model:value="form.night_end_time"
			    	valueFormat="HH:mm:ss" />
			   </a-form-item>
			  
			   <a-form-item  label="状态">
			    <a-switch v-model:checked="status" />
			   </a-form-item>
			   <a-form-item  label="规则适用人员">
			   <TransferTree @change="TransferTreeChange" :treeData.sync="treeData" :value="treeDataValue" />
			   </a-form-item>
			   
			    <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
			      <a-button type="primary" html-type="submit" @click='handleSubmit'>
			      保存
			      </a-button>
			    </a-form-item>
			  </a-form>
			
			
		<!-- 	<Form ref="form" :form_data="form_data" @handleSubmit="handleSubmit">
				<template slot="status">
					<a-switch v-model:checked="status" />
				</template>
				<template slot="rule_user">
					
					<a-input hidden="true" v-decorator="['rule_user']"></a-input>
				</template>
				<template slot="day">
					
				</template>
				<template slot="night">
					
				</template>
			</Form> -->
		</div>
	</div>
</template>

<script>
	import Form from "@/components/Form";
	import {
		message
	} from 'ant-design-vue'
	import {
		post_user
	} from '@/api/api'
	import TransferTree from "@/components/TransferTreeTwo";
	import {
		saveRuleInfo,
		getRuleDetail,
	} from "@/api/supervision";
	export default {
		components: {
			Form,
			TransferTree
		},
		data() {
			return {
				night_end_time: '',
				day_start_time: '',
				day_end_time: '',
				night_start_time: '',
				treeData: [],
				rule_user: [],
				treeDataValue: [],
				expand: false,
				loading: true,
				status: false,
				datas: {},
				form:{
					
				},
				form_data: {
					title: "基本信息",
					show_submit_btn: true,
					show_close_btn: true,
					close_reset_btn: true,
					list: [{
							type: 'text',
							name: 'id',
							options: {
								initialValue: '',

							},
							hidden: true
						},
						{
							required: true,
							type: 'text',
							name: 'name',
							title: '名称',
							options: {
								required: true,
								message: "请填写名称",
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'day_pass_time',
							placeholder: '请输入',
							required: true,
							title: '白班合格时长',
							suffix: "小时",
							options: {
								required: true,
								message: "请填写白班合格时长",
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'night_pass_time',
							placeholder: '请输入',
							required: true,
							title: '夜班合格时长',
							suffix: "小时",
							options: {
								required: true,
								message: "请填写夜班合格时长",
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'pass_num',
							placeholder: '请输入',
							title: '合格天数',
							suffix: "天",
							options: {
								message: "请填写晚班应执行天数",
								initialValue: ''
							},

						},
						{
							type: 'text',
							name: 'night_pass_num',
							placeholder: '请输入',
							title: '晚班应执行天数',
							required: true,
							suffix: "天",
							options: {
								required: true,
								message: "请填写晚班应执行天数",
								initialValue: ''
							},
						},
						{
							type: 'text',
							name: 'unneed_day',
							placeholder: '请输入',

							title: '免执行天数',
							suffix: "天",
							options: {
								initialValue: ''
							},
						},
						{
							type: 'slot',
							name: 'day',
							placeholder: '请输入',
							title: '白班执行时间',
							required: true,
							options: {
								required: true,
								message: "请输入白班执行时间"
							},
						},
						{
							type: 'slot',
							name: 'night',
							placeholder: '请输入',
							required: true,
							title: '晚班执行时间',
							options: {
								required: true,
								message: "请输入白班执行时间"
							},
						},
						{
							type: 'slot',
							name: 'status',
							placeholder: '请选择',
							title: '状态',
							options: {},
							list: [{
								key: 1,
								value: '启用'
							}, {
								key: -1,
								value: '停用'
							}],
						},
						{
							type: 'slot',
							name: 'rule_user',
							title: '添加人员',
							options: {},
						}
					]
				},

			};
		},
		created() {
			post_user().then(res => {
				this.treeData = this.$method.get_user_department_list(res.data.list,
					'sysUserTree')

				this.$nextTick(() => {
					if (this.$route.query.id) {
						this.edit(this.$route.query)
					}
				})

			})

		},
		methods: {
			TransferTreeChange(value) {
				this.rule_user = value.join(',')
			},
			edit(record) {
				getRuleDetail({
					data: {
						id: record.id
					}
				}).then(res => {
					let data = res.data.list

					// this.form_data.list.forEach(item => {
						// if (item.name == 'rule_user') {
							let treeDataValue = [];
							data.rule_user.map(io => {
								treeDataValue.push("user_" + io.id)
								io.key = "user_" + io.id;
								io.title = io.username;
								return io;
							});
							this.treeDataValue = JSON.parse(JSON.stringify(treeDataValue));
							// this.$refs.form.setFieldsValue({
							// 	[item.name]: treeDataValue.join(',')
							// })
							this.$forceUpdate()
						// } else if (item.name == 'status') {
						// } else {
						// 	if (item.type != 'slot') {
						// 		item.options.initialValue = data[item.name]
						// 	}
						// }
						this.$forceUpdate()
					// })
					this.form = data
					this.status = data.status == 1 ? true : false
					// this.day_start_time = data.day_start_time
					// this.day_end_time = data.day_end_time
					// this.night_start_time = data.night_start_time
					// this.night_end_time = data.night_end_time
				})
			},
			handleSubmit(e) {
				let data =this.form;
				if (!data.id) {
					data.id = '';
				}
				if (data.unneed_day > data.pass_num) {
					message.error('免执行天数应小于等于合格天数');
					return false
				}
				saveRuleInfo({
					data: {
						...data,
						status: this.status ? 1 : -1,
						rule_user: this.rule_user
					},
					info: true,
				}).then(res => {
					this.$router.back()
				})
			},

		}
	};
</script>
<style lang="less" scoped>
	.map_select {
		position: relative;

		.serach_input {
			position: absolute;
			top: 20px;
			left: 20px;
		}
	}

	ul {
		padding: 0;
	}

	li {
		display: block;
		list-style: none;
		display: flex;

		span {
			padding-right: 10px;

			display: inline-block;
		}
	}

	.avatar-uploader>.ant-upload {
		width: 128px;
		height: 128px;
	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	.spin-content {
		border: 1px solid #91d5ff;
		background-color: #e6f7ff;
		padding: 30px;
	}

	.distributor {
		width: 60%;
		margin-left: 1.5%;
		padding: 5px 10px;
		padding-bottom: 10px;
	}

	.distributor_box {
		background-color: rgba(250, 250, 250, 1);
		margin-left: 14%;
		padding-bottom: 20px;

		.title {
			margin-left: 24px;
		}

		/deep/.ant-col-5 {
			width: 7%;
		}
	}
.page_content .ant-col-19 {
	    width: 34.166667% !important;
	}
	
</style>